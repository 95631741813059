<template>
  <div class="custom-amount-form">
    <Header />
    <section class="main">
      <CustomAmount />
      <ReceiptSend />
      <PayMethods />
      <Footer />
    </section>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { SET_LOADING } from '../store/mutations.type'
import Header from '@/components/Header.vue'
import CustomAmount from '@/components/CustomAmount.vue'
import ReceiptSend from '@/components/ReceiptSend.vue'
import PayMethods from '@/components/PayMethodsWithButton.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'CustomAmountForm',
  components: {
    Header,
    CustomAmount,
    ReceiptSend,
    PayMethods,
    Footer,
  },
  methods: {
    ...mapMutations({
      setLoading: SET_LOADING,
    }),
  },
  mounted() {
    this.setLoading(true)
  },
}
</script>
