<template>
  <section class="order">
    <h2 class="order__title editable-products__title">Ваш заказ</h2>
    <div class="editable-products__products">
      <EditableOneProduct
        v-for="product in products"
        :key="product.id"
        :product="product"
        class="editable-products__product"
      />
    </div>

    <FeedBackForm />

    <div class="editable-products__total">
      <h2 class="order__title editable-products__title">Итого</h2>
      <div class="order__amount editable-products__amount">
        <money :value="total" />
      </div>
    </div>
  </section>
</template>

<script>
import EditableOneProduct from '@/components/checkout/AvailableItemsBlock/EditableOneProduct'
import FeedBackForm from '@/components/checkout/FeedBackForm'
import getProductsCountInfo from '@/mixins/getProductsCountInfo'
import cartBlockChange from '@/mixins/cartBlockChange'

export default {
  mixins: [getProductsCountInfo, cartBlockChange],
  components: {
    FeedBackForm,
    EditableOneProduct,
  },
  computed: {
    cartTotal() {
      return this.cartBlock?.body.find((item) => item.name === 'total')
    },
  },
  watch: {
    total(val) {
      this.cartTotal.value = val
    },
  },
  mounted() {
    if (this.cartTotal) {
      this.cartTotal.value = this.total
    }
  },
}
</script>

<style lang="scss">
@import '~@/assets/scss/components/editable-products.scss';
</style>
