<template>
  <section>
    <section class="fixed-amount field">
      <header class="field__title">Сумма к оплате</header>
      <div class="fixed-amount__amount"><money value="0" /></div>
    </section>
    <ReceiptSend />
    <section>
      <h2>Способ оплаты</h2>
      <div class="fns-pay-methods">
        <button
          v-for="pm in payMethods"
          :key="pm"
          class="pay-methods__button"
          :class="{ 'fns-pay-method_selected': payMethod === 'applepay' }"
          style="opacity: 0.5"
          disabled
        >
          <img :src="require(`@/assets/img/${pm}.svg`)" alt />
          <span style="font-weight: 500; margin-left: 5px" v-if="pm === 'card'"
            >Карта</span
          >
        </button>
      </div>
    </section>
    <section>
      <div
        id="payment-form"
        class="fns-payment-form"
        :class="{ 'fns-payment-form_hidden': !isPaymentFormVisible }"
      ></div>
    </section>
    <!-- Проверить надо ли сюда ещё что-то вставить из оплаты фнс -->
    <section class="fns-sbp-qrcode" :class="{ 'fns-sbp-qrcode_visible': showSbpQrcode }">
      <canvas id="sbp-qrcode"></canvas>
      <div class="fns-sbp-qrcode__text">
        Перейдите в раздел переводов через СБП в мобильном приложении своего банка,
        сканируйте QR-код и оплатите. Или нажмите на кнопку для выбора банка для оплаты
      </div>
    </section>
    <Footer />
  </section>
</template>

<script>
import { getTemplate } from '@/api/template'
// import receivePage from "@/store/mutations.type";
// import { invoicePaymentData } from "@/api/fns";
import { RECEIVE_PRODUCTS } from '@/store/mutations.type'
import ReceiptSend from '@/components/checkout/ReceiptSend.vue'
import Footer from '@/components/Footer.vue'
import { Block } from '@/constants'
// import { isApplePayAvailable } from "@/utils/apple-pay";
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
// import { validateEmail, validatePhone } from "@/utils";
import { SET_VALUE } from '@/store/mutations.type'
export default {
  data() {
    return {
      page: null,
      isPaymentFormVisible: false,
      langSelectorVisible: false,
      lang: 'RU',
      langs: ['GR', 'FR', 'EN', 'RU'],
      payMethod: null,
    }
  },
  components: {
    ReceiptSend,
    Footer,
  },
  computed: {
    ...mapState({
      // saleInfo: (state) => state.pay.invoicePaymentData.saleInfo,
      // totalAmount: (state) => state.pay.invoicePaymentData.totalAmount,
      // merchantName: (state) => state.pay.invoicePaymentData.merchantName,
      // invoiceNumber: (state) => state.pay.invoicePaymentData.invoiceNumber,
      // inn: (state) => state.pay.invoicePaymentData.inn,
      // date: (state) => state.pay.invoicePaymentData.date,
      email: (state) => state.pay.email,
      initPaymentData: (state) => state.pay.initPaymentData,
      paymentCardData: (state) => state.pay.paymentCardData,
      isReadyToGooglePay: (state) => state.pay.googlepayData.isReadyToPay,
      // payResultData: (state) => state.pay.invoicePaymentData,
    }),
    ...mapGetters({
      isCardPaymentAvailable: 'pay/isCardPaymentAvailable',
      isSbpPaymentAvailable: 'pay/isSbpPaymentAvailable',
      isApplepayPaymentAvailable: 'pay/isApplepayPaymentAvailable',
      isGooglePaymentAvailable: 'pay/isGooglepayPaymentAvailable',
      paymentDataCardUrl: 'pay/paymentDataCardUrl',
      isDesktop: 'pay/isDesktop',
      showSbpQrcode: 'pay/showSbpQrcode',
      email: 'email',
      phone: 'phone',
      sendType: 'sendType',
      isValid: 'isValid',
      headerBlock: 'headerBlock',
    }),
    payMethods() {
      return this.page
        ?.getBlock(Block.PaymentMethodsBlock, 'checkout')
        ?.body.find((item) => item.name === 'methods').value
    },
  },
  methods: {
    ...mapActions({
      requestPaySbp: 'pay/requestPaySbp',
      requestPaymentCard: 'pay/requestPaymentCard',
      startApplePay: 'pay/startApplePay',
      startGooglePay: 'pay/startGooglePay',
      waitForIsReadyToGooglePay: 'pay/waitForIsReadyToGooglePay',
      // requestInvoicePaymentData: "pay/requestInvoicePaymentData",
    }),
    ...mapMutations({
      setValue: SET_VALUE,
      setEmail: 'pay/setEmail',
      setSuccess: 'pay/setSuccess',
      receiveProducts: RECEIVE_PRODUCTS,
    }),
    selectLang: function (event) {
      const lang = event.target.value
      this.toggleLangs()
      this.lang = lang
    },
    toggleLangs: function () {
      this.langSelectorVisible = !this.langSelectorVisible
    },
  },
  mounted() {
    getTemplate().then((res) => {
      this.page = res
      this.$store.state.page = res
      this.templateId = this.$route.query.template
    })
  },
}
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/components/pay-methods.scss';
@import '@/views/fns/styles.scss';
@import '@/views/Fix/styles.scss';
</style>
