<template>
  <div class="menu-form" v-if="!isLoading">
    <Header />
    <Merchant />
    <section class="main" v-bind:class="caseName">
      <template v-if="caseName == 'donation'">
        <ChooseAmount />
        <ReceiptSend />
        <PayMethods />
      </template>
      <template v-if="caseName == 'order'">
        <Menu />
        <PayButton :buttonName="'Заказать'" :isVisible="true" />
      </template>
    </section>
    <Footer />
  </div>
</template>
  
<script>
// import Checkout from '@/components/checkout/index'
import Header from '@/components/blocks/Header.vue'
import Merchant from '@/components/blocks/Merchant.vue'
import Footer from '@/components/Footer'
import getPageTemplate from '@/mixins/getPageTemplate'
import setRoute from '@/mixins/setRoute'
import ChooseAmount from '@/components/tips/ChooseAmount'
import PayMethods from '@/components/checkout/PayMethods'
import ReceiptSend from '@/components/checkout/ReceiptSend'
import Menu from '@/components/blocks/Menu/index.vue'
import PayButton from '@/components/blocks/PayButton/index.vue'

import { mapGetters } from 'vuex'

export default {
  mixins: [getPageTemplate, setRoute],
  components: {
    Header,
    Merchant,
    Footer,
    ChooseAmount,
    PayMethods,
    ReceiptSend,
    Menu,
    PayButton,
  },
  computed: {
    ...mapGetters({
      caseName: 'caseName',
    }),
  },
}
</script>
