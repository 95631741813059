<template>
  <div class="order-connect">
    <div v-if="merchantButtons.length" class="order-connect__description">
      Если есть вопросы по заказу, свяжитесь с нами через мессенджеры
    </div>

    <div class="order-connect__buttons">
      <a
        v-for="(merchant, index) in merchant_contacts"
        :key="index"
        :href="`https://${getSlug(merchant.name)}.me/${merchant.value}`"
        v-show="['whatsapp', 'telegram'].includes(merchant.name)"
        class="order-connect__button"
      >
        <img
          :src="require(`@/assets/img/${merchant.name}.svg`)"
          :alt="`${merchant.name} icon`"
        />
        <span>{{ merchant.name }}</span>
      </a>
      <template v-if="merchantContactPhone">
        <div v-if="merchantButtons.length" class="order-connect__description mt-5 pt-5">
          Или по номеру телефона
        </div>
        <div v-else class="order-connect__description mt-0 pt-0">
          Вы можете связаться с организаторами по телефону
        </div>
        <a class="order-connect__phone" :href="`tel:${merchantContactPhone.value}`">
          <img
            :src="require(`@/assets/img/${merchantContactPhone.name}.svg`)"
            :alt="`${merchantContactPhone.name} icon`"
          />
          <span>{{ merchantContactPhone.value }}</span>
        </a>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MerchantContactsBlock',
  props: {
    merchant_contacts: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    merchantContactPhone() {
      return this.merchant_contacts?.find((item) => item.name === 'phone')
    },
    merchantButtons() {
      return this.merchant_contacts?.filter((item) => item.name !== 'phone')
    },
  },
  methods: {
    getSlug(val) {
      switch (val) {
        case 'telegram':
          return 't'
        case 'whatsapp':
          return 'wa'
      }
    },
  },
}
</script>
