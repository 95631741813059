<template>
  <div ref="tags" class="menu-tags">
    <div
      v-for="tag in tags"
      :key="tag"
      class="menu-tag"
      :class="{ ['menu-tag_selected']: tag === menuTag }"
      @click="onClick(tag)"
    >
      {{ tag }}
    </div>
    <div class="menu-tag_selected--background" ref="selectedBg"></div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { SET_TAG } from '@/store/mutations.type'

export default {
  props: ['tags', 'scrollTo'],
  computed: {
    ...mapState({ menuTag: (state) => state.menu.tag }),
  },
  methods: {
    ...mapMutations({
      setTag: SET_TAG,
    }),
    onClick(tag) {
      this.setTag(tag)
      this.scrollTo(tag)
    },
    moveToScreen() {
      const menuTags = this.$refs.tags
      const screenWidth = menuTags.offsetWidth
      const selected = document.querySelector('.menu-tag_selected')
      const scrollLeft = menuTags.scrollLeft
      const padding = 10
      // справа
      if (selected.offsetLeft + selected.offsetWidth > scrollLeft + screenWidth) {
        menuTags.scroll({
          top: 0,
          left: selected.offsetLeft + selected.offsetWidth - screenWidth + padding,
          behavior: 'smooth',
        })
      }
      // слева
      if (selected.offsetLeft < scrollLeft) {
        menuTags.scroll({
          top: 0,
          left: selected.offsetLeft - padding,
          behavior: 'smooth',
        })
      }

      this.findTabActive()
    },
    findTabActive() {
      const { selectedBg } = this.$refs
      const activeItem = document.querySelector('.menu-tag_selected')
      if (selectedBg) {
        selectedBg.style.width = `${activeItem.clientWidth}px`
        selectedBg.style.left = `${activeItem.offsetLeft}px`
      }
    },
  },
  updated() {
    this.moveToScreen()
  },
  mounted() {
    this.$nextTick(() => this.menuTag && this.findTabActive())
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/components/menu.scss';
</style>
