import Vue from 'vue'
// @ts-ignore
import VueIntl from 'vue-intl'
// @ts-ignore
import smoothscroll from 'smoothscroll-polyfill'

import App from './App.vue'
import router from './router'
import store from './store'
import Money from './components/Money.vue'
import AppWrap from './components/AppWrap.vue'
import vuetify from './plugins/vuetify'

smoothscroll.polyfill()

function runApp() {
  Vue.config.productionTip = false

  Vue.use(VueIntl)
  // @ts-ignore
  Vue.setLocale('ru')

  Vue.component('money', Money)
  Vue.component('app-wrap', AppWrap)

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app')
}

// @ts-ignore
if (!global.Intl) {
  require.ensure(
    ['intl', 'intl/locale-data/jsonp/en.js', 'intl/locale-data/jsonp/ru.js'],
    function (require) {
      require('intl')
      require('intl/locale-data/jsonp/en.js')
      require('intl/locale-data/jsonp/ru.js')
      runApp()
    },
  )
} else {
  runApp()
}
