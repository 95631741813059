<template>
  <div class="payment-success menu-form">
    <Header />
    <Merchant />
    <section class="main">
      <div class="success__message">
        Ваша оплата прошла успешно! <a href="#">Вот ссылка на чек</a>
      </div>
      <Products :showTitle="false" />
    </section>
  </div>
</template>

<script>
import Header from '@/components/blocks/Header.vue'
import Merchant from '@/components/blocks/Merchant.vue'
import getPageTemplate from '@/mixins/getPageTemplate'
import setRoute from '@/mixins/setRoute'
import Products from '@/components/Products.vue'

export default {
  mixins: [getPageTemplate, setRoute],
  components: {
    Header,
    Merchant,
    Products,
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables';

.success {
  &__message {
    font-size: 43px;
    font-weight: 300;

    a {
      color: $color;
    }
  }
}
</style>
