<template>
  <section>Loading...</section>
</template>

<script>
import { cfg } from '@/api/fns'
import axios from 'axios'

export default {
  name: 'Alias',
  mounted() {
    const getAlias = async () => {
      const alias = await axios.get(`/pages/aliases/${this.$route.params.aliasUuid}`, cfg)
      return alias
    }
    getAlias().then((res) => {
      let params = res.data.target_params
      if (
        params.meta.category == null &&
        params.meta.room == null &&
        params.meta.table == null
      ) {
        return this.$router.push(
          `/${res.data.target_type}?template=${params.template_id}`,
        )
      }
      if (params.meta.category == null && params.meta.room == null) {
        return this.$router.push(
          `/${res.data.target_type}?template=${params.template_id}&table=${params.meta.table}`,
        )
      }

      if (params.meta.table == null && params.meta.room == null) {
        return this.$router.push(
          `/${res.data.target_type}?template=${params.template_id}&category=${params.meta.category}`,
        )
      }

      if (params.meta.table == null && params.meta.category == null) {
        return this.$router.push(
          `/${res.data.target_type}?template=${params.template_id}&room=${params.meta.room}`,
        )
      }

      if (params.meta.room == null) {
        return this.$router.push(
          `/${res.data.target_type}?template=${params.template_id}&table=${params.meta.table}&category=${params.meta.category}`,
        )
      }

      if (params.meta.category == null) {
        return this.$router.push(
          `/${res.data.target_type}?template=${params.template_id}&room=${params.meta.room}&table=${params.meta.table}`,
        )
      }

      if (params.meta.table == null) {
        return this.$router.push(
          `/${res.data.target_type}?template=${params.template_id}&room=${params.meta.room}&category=${params.meta.category}`,
        )
      }

      return this.$router.push(
        `/${res.data.target_type}?template=${params.template_id}&room=${params.meta.room}&table=${params.meta.table}&category=${params.meta.category}`,
      )
    })
  },
}
</script>
