<template>
  <div v-if="!isLoading" class="menu-form">
    <Header />
    <Merchant />
    <section class="main">
      <ChooseAmount />
      <ReceiptSend />
      <PayMethods />
    </section>
    <Footer />
  </div>
</template>

<script>
import getMenuInfo from '@/mixins/getMenuInfo'
import ReceiptSend from '@/components/checkout/ReceiptSend'
import PayMethods from '@/components/checkout/PayMethods'
import Footer from '@/components/Footer'
import ChooseAmount from '@/components/ChooseAmount'
import setRoute from '@/mixins/setRoute'

export default {
  mixins: [getMenuInfo, setRoute],
  components: {
    ChooseAmount,
    Footer,
    ReceiptSend,
    PayMethods,
  },
}
</script>

<style lang="scss">
@import '~@/assets/scss/pages/index.scss';
</style>
