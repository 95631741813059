<template>
  <!-- Есть Item.vue - компонент обычного товара без модификаторов
  Я написал Item2.vue опираясь на существующий Item.vue. Разница в том,
  что в Item2 есть radiobuttons позволяющие выбрать необходимый размер продукта
  и умеет обрабатывать другой формат строго структурированного json'a. -->
  <section class="menu-item">
    <div v-if="product.image_url" class="menu-img">
      <img :src="product.image_url" alt="" />
    </div>
    <div v-else class="menu-img placeholder" style="display: none">
      <img :src="photoPlaceholder" alt="Placeholder" />
    </div>
    <header class="menu-header">{{ product.name }}</header>
    <div style="margin-bottom: 15px" class="menu-description">
      {{ product.description }}
    </div>
    <div
      class="menu-item_radiobtn-container"
      v-for="(prod, index) in product.prices"
      :key="prod.id"
    >
      <input
        class="menu-item_radiobtn-container_input"
        @click="changeProduct(prod, index)"
        :checked="prod.id == 1"
        type="radio"
        :id="product.name + prod.description + prod.id"
        :name="'product' + product.id"
        :value="prod.description"
      />
      <label
        class="menu-item_radiobtn-container_label"
        :for="product.name + prod.description + prod.id"
      >
        <span class="menu-item_radiobtn-container_label_span"
          >{{ prod.description + ' (' + prod.weight + prod.unit + ')' }}
          <span class="menu-item_radiobtn-container_label_span_price">{{
            prod.price + '₽'
          }}</span></span
        ></label
      >
    </div>
    <!-- eslint-disable -->
    <ItemsButton
      v-for="(quantity, index) in qty"
      :price="product.prices[index].price"
      :index="index"
      v-if="index == productSizeIndex"
      :quantity="qty"
      :onAdd="add"
      :onRemove="remove"
      :processWidth="processWidth"
    />
    <!-- eslint-enable -->
  </section>
</template>

<script>
import ItemsButton from '@/components/ItemsButton.vue'
import editableItemsItem from '@/mixins/editableItemsItem'

export default {
  mixins: [editableItemsItem],
  props: ['product'],
  data: () => ({
    productSizeIndex: 0,
    image_url: '',
    photoPlaceholder: require('@/assets/img/photo_placeholder.svg'),
  }),
  components: {
    ItemsButton,
  },
  mounted() {
    if (this.product.image_url == null) {
      this.image_url = false
    } else this.image_url = true

    this.price = this.product.prices[0].price
  },
  methods: {
    changeProduct(prod, index) {
      this.price = prod.price
      this.productSizeIndex = index
    },
  },
}
</script>

<style lang="scss">
@import '~@/assets/scss/components/menu.scss';
</style>
