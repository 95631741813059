<template>
  <section class="custom-amount field">
    <header class="field__title">Введите сумму</header>
    <div>
      <div class="custom-amount__input-wrap">
        <input
          type="text"
          class="custom-amount__input"
          placeholder="0"
          :value="amount"
          @input="onChange($event)"
          :style="styleObject"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { SET_VALUE } from '../store/mutations.type'
import { processAmount } from '../utils'

function getWidthOfInput(value) {
  const tmp = document.createElement('span')
  tmp.className = 'custom-amount__input tmp-element'
  tmp.innerHTML = value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
  document.body.appendChild(tmp)
  // const theWidth = tmp.getBoundingClientRect().width;
  const tmpWidth = tmp.offsetWidth + 10 // 10 из-за странного поведения на ios
  document.body.removeChild(tmp)
  return tmpWidth
}

export default {
  name: 'CustomAmount',
  computed: {
    styleObject: function () {
      return { width: getWidthOfInput(this.amount || '0') + 'px' }
    },
    ...mapGetters(['amount', 'decSep']),
  },
  methods: {
    onChange(e) {
      const nextValue = processAmount({
        newVal: e.target.value,
        oldVal: this.amount,
        decSep: this.decSep,
      })
      // https://stackoverflow.com/questions/59263861/vue-vuex-input-value-not-set-by-store-if-no-store-change
      this.$emit('input', (e.target.value = nextValue))
      this.setValue({ key: 'amount', value: nextValue })
    },
    ...mapMutations({
      setValue: SET_VALUE,
    }),
  },
}
</script>

<style lang="scss">
.tmp-element {
  visibility: hidden;
  white-space: pre;
}

.custom-amount {
  &__input-wrap {
    display: flex;
    justify-content: center;
    height: 78px;
    margin-top: 16px;
    background-color: #fff;
    border-radius: 18px;
    border: solid 2px #fff;
    overflow: scroll;

    &_invalid {
      border-color: #ff1515;

      input {
        color: #ff1515;
      }

      &::after {
        color: #ff1515;
      }
    }

    &::after {
      content: '₽';
      display: inline-block;
      font-weight: 300;
      font-size: 43px;
      align-self: center;
      // line-height: 78px;
    }
  }

  &__input {
    margin-right: 12px;
    font-weight: 300;
    font-size: 43px;
    // line-height: 52px;
    border: none;
    outline: none;
  }
}
</style>
