<template>
  <section class="menu-items-block">
    <!-- eslint-disable -->
    <Item
      v-if="item.price != undefined"
      v-for="item in items"
      :product="item"
      :key="item.id"
    />
    <Item2 v-else :product="item" />
    <!-- eslint-enable -->
  </section>
</template>

<script>
import Item from './Item.vue'
import Item2 from './Item2.vue'

export default {
  components: { Item, Item2 },
  props: ['items', 'tag'],
}
</script>
