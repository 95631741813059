<template>
  <div v-if="checkoutTemplate" class="order-check">
    <button
      v-if="pageTemplate.case === 'tickets'"
      class="order-check__try-again order-check__download"
    >
      <img :src="require('@/assets/img/tickets/arrow-download.svg')" alt="" />
      Скачать билеты
    </button>

    <h2 v-if="pageTemplate.case !== 'tickets'" class="order-check__title">
      {{ checkoutTemplate.orderNumber }}
    </h2>

    <div v-if="pageTemplate.case !== 'tickets'" class="order-check__number">
      {{ invoiceNumber }}
    </div>

    <div v-if="pageTemplate.resultText" class="order-check__message">
      <p v-html="resultText(checkoutTemplate.resultText)"></p>
      <span v-if="pageTemplate.case === 'tickets'">{{ sendItem }}</span>
    </div>

    <div v-if="paymentResult.status">
      <MerchantContactsBlock
        v-if="showConnect"
        :merchant_contacts="checkoutTemplate.merchant_contacts"
      />

      <CheckProducts :products="products" />

      <div class="order__title order-check__total-title">Итого</div>

      <div class="order__amount order-check__amount">
        <money :value="total" />
      </div>
    </div>
  </div>
</template>

<script>
import CheckProducts from '@/components/checkout/checkout-result/CheckProducts'
import MerchantContactsBlock from '@/components/checkout/checkout-result/MerchantContactsBlock'
import { Block } from '@/constants'
import { mapGetters } from 'vuex'

export default {
  components: {
    CheckProducts,
    MerchantContactsBlock,
  },
  props: ['paymentResult', 'showConnect', 'products', 'total', 'invoiceNumber'],
  data: () => ({
    checkoutTemplate: null,
  }),
  computed: {
    ...mapGetters(['pageTemplate', 'route', 'sendType']),
    sendItem() {
      return this.$store.getters[this.sendType]
    },
  },
  methods: {
    getCheckout(page) {
      this.checkoutTemplate = {
        orderNumber: this.pageTemplate.getBlock(Block.OrderNumberBlock, page).body.find(
          (item) => item.name === 'label',
        ).value,
        resultText: this.pageTemplate.getBlock(Block.ResultTextBlock, page)?.body.find(
          (item) => item.name === 'result_text',
        ).value,
        merchant_contacts: this.pageTemplate.getBlock(Block.MerchantContactsBlock, page)
          ?.body,
      }
    },
    resultText(text) {
      return text.replace('{target}', this.sendType === 'email' ? 'почту:' : 'телефон:')
    },
  },
  mounted() {
    this.getCheckout(this.route.current)
  },
}
</script>
