import Header from '@/components/Header'
import { mapGetters } from 'vuex'

export default {
  components: {
    Header,
  },
  data: () => ({
    payResult: null,
    showConnect: true,
  }),
  computed: {
    ...mapGetters(['pay/payResultData', 'pagePath', 'route']),
    paymentResult() {
      return {
        status: this.route.current.includes('error')
          ? this.payErrorData?.status
          : this.payResult.status,
        message: this.payErrorData?.error,
      }
    },
    payErrorData() {
      return this['pay/payResultData']
    },
  },
  mounted() {
    if (!this.$route.query?.invoice_id) {
      this.payResult = this['pay/payResultData']
    }
  },
}
