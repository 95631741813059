<template>
  <section v-if="ticketsEvent" class="ticket" :class="{ empty: !ticketsCount || error }">
    <div class="ticket__date">{{ date }}</div>

    <h1>{{ name }}</h1>

    <h2 v-if="description" class="ticket__subheading">{{ description }}</h2>

    <div class="ticket__image">
      <img :src="photo" alt="" />

      <div v-if="!ticketsCount || error" class="ticket__empty">
        <p v-if="error === 'all_not_available'">Все билеты распроданы</p>
        <p v-else>Выбранные билеты распроданы</p>
      </div>
    </div>

    <button
      v-if="error && error === 'item_not_available'"
      @click="tryAgain()"
      class="order-check__try-again"
    >
      ← Попробовать ещё раз
    </button>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  props: ['ticketsCount', 'error'],
  computed: {
    ...mapGetters({
      ticketsEvent: 'event',
    }),
    name() {
      return this.ticketsEvent.find((item) => item.name === 'name')?.value || null
    },
    photo() {
      return this.ticketsEvent
        ? this.ticketsEvent.find((item) => item.name === 'logo_url')?.value
        : null
    },
    description() {
      return this.ticketsEvent.find((item) => item.name === 'name')?.description || null
    },
    date() {
      return (
        moment(this.ticketsEvent.find((item) => item.name === 'datetime')?.value)
          .lang('ru')
          .format('DD MMMM YYYY, HH:mm') || null
      )
    },
  },
  methods: {
    async tryAgain() {
      await this.$router.push(`/tickets?template=${this.$route.query.template}`)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/components/event.scss';
</style>
