<template>
  <section v-if="total" class="pay-button-wrap">
    <div class="pay-button-wrap__inner">
      <button v-if="isVisible" @click="nextPage" class="pay-button">
        <div class="pay-button__text">
          <p>{{ buttonName }}</p>
        </div>
        <div class="pay-button__amount">
          <money :value="total" currency="RUB" />
        </div>
      </button>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { REQUEST_ORDER } from '@/store/actions.type'
import { Block } from '@/constants'
import cartBlockChange from '@/mixins/cartBlockChange'

export default {
  mixins: [cartBlockChange],
  props: ['onClick', 'amount', 'currency', 'isVisible', 'buttonName'],
  computed: {
    ...mapGetters(['caseName', 'total', 'pageTemplate', 'localProducts', 'route']),
  },
  watch: {
    total(val) {
      this.updateCartBlock(val)
    },
  },
  methods: {
    ...mapActions({
      requestOrder: `pay/${REQUEST_ORDER}`,
    }),
    updateCartBlock(val) {
      if (this.cartBlock) {
        this.cartBlock.body.find((item) => item.name === 'items').value =
          this.localProducts
        this.cartBlock.body.find((item) => item.name === 'total').value = val
      }
    },
    nextPage() {
      switch (this.caseName) {
        case 'order':
          this.getInvoice()
          break
        default:
          if (
            this.pageTemplate.getBlock(Block.AvailableItemsBlock)
              .name == 'AvailableItemsBlock'
          ) {
            return this.$router.push({ name: 'CheckoutItems' })
          }
          this.$router.push('/menu/checkout')
          break
      }
    },
    async getInvoice() {
      this.pageTemplate.getBlock(Block.CartBlock).body =
        this.cartBlock.body
      try {
        await this.requestOrder({
          cartBlock: {
            ...this.pageTemplate,
          },
          payMethod: 'order',
        })
        await this.$router.push('/checkout-success')
      } catch (e) {
        console.error(e)
        await this.$router.push('/checkout-error')
      }
    },
  },
  mounted() {
    this.updateCartBlock(this.total)
  },
}
</script>

<style lang="scss">
@import '~@/assets/scss/components/pay-button.scss';
</style>
