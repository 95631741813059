<template>
  <div class="menu-form" v-if="!isLoading">
    <Header />
    <Merchant />
    <section class="main">
      <ChooseAmount />
      <ReceiptSend />
      <PayMethods />
    </section>
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import getMenuInfo from '@/mixins/getMenuInfo'
import ChooseAmount from '@/components/tips/ChooseAmount'
import PayMethods from '@/components/checkout/PayMethods'
import Footer from '@/components/Footer'
import ReceiptSend from '@/components/checkout/ReceiptSend'
import setRoute from '@/mixins/setRoute'

export default {
  mixins: [getMenuInfo, setRoute],
  components: {
    ReceiptSend,
    Footer,
    PayMethods,
    ChooseAmount,
  },
  created() {
    localStorage.setItem('templateId', JSON.stringify(this.$route.query.template))
  },
  computed: {
    ...mapGetters({
      paymentSuccess: 'pay/paymentSuccess',
    }),
  },
  watch: {
    paymentSuccess: function(status) {
      if (status) {
        this.$router.push('/success')
      }
    },
  },
}
</script>
