<template>
  <section class="pay-methods">
    <h2 class="order__title pay-methods__title">Способы оплаты</h2>
    <div class="pay-methods__buttons">
      <!--        :class="'pay-methods__button_' + pm"-->
      <button
        v-for="(pm, index) in payMethods"
        :key="pm"
        class="pay-methods__button"
        :class="{
          active: cardPay && pm === 'card',
        }"
        @click="payment(pm)"
      >
        <!-- <img
          :src="
            require(`@/assets/img/${
              pm === 'card' && cardPay ? pm + '_white' : pm
            }.svg`)
          "
          alt
        /> -->
        <img
          :src="require(`@/assets/img/${index % 2 === 0 ? pm + '_white' : pm}.svg`)"
          alt
        />
        <span v-if="pm === 'card'">Карта</span>
      </button>
    </div>

    <div class="card-form" v-if="showCardForm">
      <div
        v-if="paymentDataCardUrl === null"
        id="payment-form"
        class="fns-payment-form"
        :class="{ 'fns-payment-form_hidden': !showCardForm }"
      ></div>
      <iframe v-if="paymentDataCardUrl !== null" class="card-payment-frame" :src="paymentDataCardUrl"></iframe>
    </div>

    <section class="sbp-qrcode" :class="{ 'sbp-qrcode_visible': showSbpQrcode }">
      <canvas id="sbp-qrcode"></canvas>
      <div class="sbp-qrcode__text">
        Перейдите в раздел переводов через СБП в мобильном приложении своего банка,
        сканируйте QR-код и оплатите. Или нажмите на кнопку для выбора банка для оплаты
      </div>
    </section>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { REQUEST_ORDER } from '@/store/actions.type'
import { Block } from '@/constants'
import { validateEmail, validatePhone } from '@/utils'
import {
  CHANGE_TOTAL_ERROR,
  FEEDBACK_EMPTY_CHANGE,
  SET_VALUE,
} from '@/store/mutations.type'

export default {
  data: () => ({
    cardPay: false,
  }),
  computed: {
    ...mapGetters([
      'paymentMethods',
      'paymentBlock',
      'pageTemplate',
      'feedbackValue',
      'email',
      'phone',
      'sendType',
      'isValid',
      'route',
      'total',
      'totalAmount',
      'totalError',
    ]),
    ...mapGetters({
      showSbpQrcode: 'pay/showSbpQrcode',
      showCardForm: 'pay/showCardForm',
      paymentDataCardUrl: 'pay/paymentDataCardUrl',
    }),
    payMethods() {
      return this.paymentMethods?.body.find((item) => item.name === 'methods').value
    },
  },
  methods: {
    ...mapActions({
      requestOrder: `pay/${REQUEST_ORDER}`,
    }),
    ...mapMutations({
      setValue: SET_VALUE,
      feedBackEmptyChange: FEEDBACK_EMPTY_CHANGE,
      setTotalError: CHANGE_TOTAL_ERROR,
    }),
    validate() {
      if (this.sendType === 'phone') {
        this.setValue({
          key: 'isValid',
          value: validatePhone('ru', this.phone),
        })
      }
      if (this.sendType === 'email') {
        this.setValue({
          key: 'isValid',
          value: validateEmail(this.email),
        })
      }
      return this.isValid
    },
    feedbackValidate() {
      const feedbackForm = document.querySelector('.feedbackForm')
      if (feedbackForm) {
        const phone = this.feedbackValue?.find((item) => item.name === 'phone').value
        this.feedBackEmptyChange(validatePhone('ru', phone))
        return validatePhone('ru', phone)
      }
      return true
    },

    async payment(pm) {
      if ([this.validate(), this.feedbackValidate()].some((item) => !item)) {
        window.scrollTo(0, 0)
        return
      }

      if (!+this.total && !+this.totalAmount) {
        this.setTotalError(true)
        window.scrollTo(0, 0)
        return
      }
      if (pm === 'card') this.cardPay = true

      const feedbackForm = document.querySelector('.feedbackForm')
      if (feedbackForm) {
        this.pageTemplate.getBlock(Block.FeedbackBlock, this.route.current).body =
          this.feedbackValue
      }
      try {
        await this.requestOrder({
          cartBlock: {
            ...this.pageTemplate,
          },
          payMethod: pm,
        })
      } catch (e) {
        console.error(e)
        if (e === 'item_not_available' || e === 'all_not_available') {
          await this.$router.push(
            `/checkout-not-available?template=${this.$route.query.template}&error=${e}`,
          )
        } else {
          await this.$router.push('/checkout-error')
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/components/pay-methods.scss';
</style>
