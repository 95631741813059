<template>
  <div class="menu-form">
    <Header />
    <Merchant />
    <section class="main">
      <Fix />
    </section>
  </div>
</template>
<script>
import getMenuInfo from '@/mixins/getMenuInfo'
import setRoute from '@/mixins/setRoute'
import Fix from '@/components/blocks/Fix/index.vue'
export default {
  mixins: [getMenuInfo, setRoute],
  components: {
    Fix,
  },
}
</script>
<style lang="scss">
@import '~@/assets/scss/pages/index.scss';
</style>
