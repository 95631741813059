<template>
  <div class="menu-button">
    <div
      ref="btn"
      class="menu-button__wrap"
      :class="{ 'menu-button__wrap_empty': qntity === 0 }"
    >
      <template v-if="qntity === 0">
        <button @click="addItem" class="menu-button-empty">
          {{ formatNumber(price) }} <img src="~@/assets/img/plus2.svg" alt="" />
        </button>
      </template>
      <template v-if="qntity > 0">
        <button class="menu-button-non-empty__minus" @click="removeItem"></button>
        <div class="menu-button-non-empty__amount">
          {{ formatNumber(price) }}
          <img src="~@/assets/img/mult2.svg" alt="" />{{ qntity }}
        </div>
        <button class="menu-button-non-empty__plus" @click="addItem"></button>
      </template>
    </div>

    <div ref="tmpNext" class="menu-button__wrap menu-button__wrap_tmp-next">
      <button class="menu-button-non-empty__minus"></button>
      <div class="menu-button-non-empty__amount">
        {{ formatNumber(price) }}
        <img src="~@/assets/img/mult2.svg" alt="" />1
      </div>
      <button class="menu-button-non-empty__plus"></button>
    </div>

    <div ref="tmpPrev" class="menu-button__wrap menu-button__wrap_tmp-prev">
      <template>
        <button class="menu-button-empty">
          {{ formatNumber(price) }} <img src="~@/assets/img/plus2.svg" alt="" />
        </button>
      </template>
    </div>
  </div>
</template>

<script>
const formatter = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'RUB',
})

export default {
  data: () => ({
    qntity: 0,
    processingWidth: false,
  }),
  props: ['price', 'quantity', 'onAdd', 'onRemove', 'index', 'processWidth'],
  methods: {
    formatNumber(value) {
      return formatter.format(value)
    },
    addItem() {
      let currentPage = this.$route.fullPath
      let arr = currentPage.split('/')
      let currentLink = arr[arr.length - 1]
      if (currentLink == 'checkout') {
        this.$emit('setIndex', this.index) //Нужен для страницы checkout и динамического изменения индекса
      }

      const { btn, tmpNext } = this.$refs
      const duration = 250
      this.processingWidth = false
      if (this.quantity === 0) {
        this.processingWidth = true
        this.processWidth(tmpNext)
      }
      setTimeout(() => {
        btn.style.width = null
      }, duration)
      this.onAdd()
      this.qntity = this.quantity[this.index]
    },
    removeItem() {
      let currentPage = this.$route.fullPath
      let arr = currentPage.split('/')
      let currentLink = arr[arr.length - 1]
      if (currentLink == 'checkout') {
        this.$emit('setIndex', this.index) //Нужен для страницы checkout и динамического изменения индекса
      }

      const { btn, tmpPrev } = this.$refs
      const duration = 250
      this.processingWidth = false
      if (this.quantity === 1) {
        this.processingWidth = true
        this.processWidth(tmpPrev)
      }
      setTimeout(() => {
        btn.style.width = null
      }, duration)
      this.onRemove()
      this.qntity = this.quantity[this.index]
    },
  },
  mounted() {
    this.qntity = this.quantity[this.index]
  },
}
</script>

<style lang="scss">
$bgtr: 200ms;

.menu-button {
  display: inline-block;
  overflow: hidden;
  width: auto;
  max-width: 100%;
  color: #fff;

  &__wrap {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 14px;
    height: 50px;
    border: solid 1px #212125;
    border-radius: 10px;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.03em;
    background-color: #212125;
    transition: background-color $bgtr ease-out;

    // --smooth-corners: 4;
    // mask-image: paint(smooth-corners);
    // -webkit-mask-image: paint(smooth-corners);

    &_tmp-next,
    &_tmp-prev {
      position: absolute;
      visibility: hidden;
      z-index: 0;
    }

    &_empty {
      background-color: #fff;
      transition: background-color $bgtr ease-out;
    }
  }

  &-empty {
    padding: 0 14px 0 13px;
    color: #212125;
    transition: background-color $bgtr ease-out;
    font-size: 16px;
    white-space: nowrap;
    line-height: 18px;
    letter-spacing: -0.03em;
    border: none;
    border-radius: inherit;
    outline: none;
    font-family: 'Inter';

    & > img {
      vertical-align: top;
      margin-left: 13px;
      position: relative;
      top: 1px;
    }
  }

  &-non-empty {
    color: #fff;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    &__minus,
    &__plus {
      width: 47px;
      border: none;
      outline: none;
      height: 32px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
    }

    &__minus {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      background-image: url(~@/assets/img/minus-white2.svg);
    }

    &__plus {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      background-image: url(~@/assets/img/plus-white2.svg);
      margin-left: 2px;
    }

    &__amount {
      display: flex;
      align-items: center;
      white-space: nowrap;
      flex-wrap: nowrap;
      font-family: Inter;
      letter-spacing: -0.03em;

      & > img {
        margin: 0 6px;
        position: relative;
        top: 1px;
        left: 1px;
      }
    }
  }
}
</style>
