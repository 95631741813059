import { mapActions, mapState } from 'vuex'
import { REQUEST_PAGE } from '@/store/actions.type'

export default {
  components: {
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.isLoading,
    }),
  },
  methods: {
    ...mapActions({
      requestPage: REQUEST_PAGE,
    }),
  },
  async beforeMount() {
    await this.requestPage({ aliasOrTemplate: this.$route.params.aliasOrTemplate })
  },
}
