import { mapGetters } from 'vuex'

export default {
  data: () => ({
    cartBlock: null,
  }),
  computed: {
    ...mapGetters(['paymentBlock']),
  },
  mounted() {
    this.cartBlock = this.paymentBlock || null
  },
}
