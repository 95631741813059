<template>
  <section>
    <EditableProducts />
    <ReceiptSend />
    <PayMethods />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

import EditableProducts from './EditableProducts'
import ReceiptSend from './ReceiptSend'
import PayMethods from './PayMethods'

export default {
  components: {
    EditableProducts,
    ReceiptSend,
    PayMethods,
  },
  computed: {
    ...mapGetters({
      paymentSuccess: 'pay/paymentSuccess'
    }),
  },
  watch: {
    paymentSuccess: function(status) {
      if (status) {
        console.log("this.$router.push('/success')")
        // this.$router.push('/success')
      }
    },
  },
}
</script>
