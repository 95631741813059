import Vue from 'vue'
import VueRouter from 'vue-router'

import Success from '@/views/Success.vue'
import Fail from '@/views/Fail.vue'
import Error from '@/views/Error.vue'
import Loading from '@/views/Loading.vue'
import Fetching from '@/views/Fetching.vue'
import Menu from '@/views/Menu.vue'
import NotFound from '@/views/NotFound.vue'
import Checkout from '@/views/Checkout.vue'
import CheckoutItemsTemplate from '@/views/CheckoutItemsTemplate.vue'
import CheckoutResult from '@/components/checkout/checkout-result/CheckoutResult.vue'
import CheckoutError from '@/components/checkout/checkout-result/CheckoutError.vue'
import CustomAmount from '@/views/CustomAmount.vue'
import Order from '@/views/Order.vue'
import FixTemplateRedirectPage from '@/views/Fix/FixTemplateRedirectPage.vue'
import FixSumTemplate from '@/views/Fix/FixSumTemplate.vue'
import Tips from '@/views/Tips.vue'
import Tickets from '@/views/Tickets.vue'
import Alias from '@/views/Alias.vue'
import NotAvailable from '@/components/tickets/NotAvailable.vue'
import PageTemplate from '@/views/template/PageTemplate.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/p/:aliasOrTemplate',
    name: 'Page Template',
    component: PageTemplate,
  },
  {
    path: '/success',
    name: 'Success',
    component: Success,
  },
  {
    path: '/fetching',
    name: 'Fetching',
    component: Fetching,
  },
  {
    path: '/fail',
    name: 'Fail',
    component: Fail,
  },
  {
    path: '/error/:status',
    name: 'Error',
    component: Error,
  },
  {
    path: '/loading',
    name: 'Loading',
    component: Loading,
  },
  {
    path: '/menu/checkout',
    name: 'Checkout',
    component: Checkout,
  },
  {
    path: '/menu/items/checkout',
    name: 'CheckoutItems',
    component: CheckoutItemsTemplate,
  },
  {
    path: '/checkout-success',
    name: 'Checkout Success',
    component: CheckoutResult,
  },
  {
    path: '/checkout-error',
    name: 'Checkout Error',
    component: CheckoutError,
  },
  {
    path: '/menu',
    name: 'Menu',
    component: Menu,
  },
  {
    path: '/custom-amount',
    name: 'Custom Amount',
    component: CustomAmount,
  },
  {
    path: '/order',
    name: 'Order',
    component: Order,
  },
  {
    path: '/fix',
    name: 'FixTemplateRedirectPage',
    component: FixTemplateRedirectPage,
  },
  {
    path: '/checkout/fix/:invoiceNumber',
    name: 'FixSumTemplate',
    component: FixSumTemplate,
  },
  {
    path: '/tips',
    name: 'Tips',
    component: Tips,
  },
  {
    path: '/donation',
    name: 'Donation',
    component: Tips,
  },
  {
    path: '/tickets',
    name: 'Tickets',
    component: Tickets,
  },
  {
    path: '/alias/:aliasUuid',
    alias: '/a/:aliasUuid',
    name: 'Alias',
    component: Alias,
  },
  {
    path: '/checkout-not-available',
    name: 'Checkout Not Available',
    component: NotAvailable,
  },
  {
    path: '/*',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export default router
