<template>
  <section class="field">
    <header v-if="showTitle" class="field__title">Ваша корзина</header>
    <div class="products">
      <div v-for="p in saleInfo" v-bind:key="p.productName" class="products__product">
        <div>{{ p.productName }}</div>
        <div class="products__price">
          <money :value="p.cost" /> <img src="~@/assets/img/mult.svg" />
          {{ p.quantity }}
        </div>
      </div>
    </div>
    <div class="total">
      <div>Итого</div>
      <div class="total__amount"><money :value="totalAmount" /></div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { SET_LOADING } from '../store/mutations.type'
import { REQUEST_PRODUCTS } from '../store/actions.type'

/*
methods: {
    ...mapMutations({
      setLoading: SET_LOADING,
    }),
  },
  mounted() {
    this.setLoading(true);
  },
*/

export default {
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['saleInfo', 'totalAmount']),
  },
  methods: {
    ...mapActions({
      requestProducts: REQUEST_PRODUCTS,
    }),
    ...mapMutations({
      setLoading: SET_LOADING,
    }),
  },
  mounted() {
    this.setLoading(true)
    const invoiceId = this.$route.params.invoiceId
    this.requestProducts({ invoiceId })
  },
}
</script>

<style lang="scss">
.products {
  margin-top: 21px;
  border-bottom: dashed 1px;
  padding-bottom: 38px;

  &__product {
    &:first-child {
      margin-top: 27px;
    }

    margin-top: 35px;
  }

  &__price {
    font-weight: 600;

    > img {
      position: relative;
      top: 9px;
    }
  }
}

.total {
  margin-top: 38px;
  font-weight: 600;

  &__amount {
    font-size: 43px;
  }
}
</style>
