<template>
  <!-- <div ref="load">Загрузка</div> -->
  <div class="menu-form">
    <Header />
    <Merchant />
    <section class="main">
      <Fix />
    </section>
  </div>
</template>

<style lang="scss">
@import '~@/assets/scss/pages/index.scss';
</style>

<script>
// import Header from "@/components/blocks/Header.vue";
import getMenuInfo from '@/mixins/getMenuInfo'
import setRoute from '@/mixins/setRoute'
import Fix from '@/components/blocks/Fix/indexForRedirectPage.vue'
import { getTemplate, postInvoiceNumber } from '@/api/template'
export default {
  mixins: [getMenuInfo, setRoute],
  components: {
    Fix,
    // Header,
  },
  created() {
    localStorage.setItem('templateId', JSON.stringify(this.$route.query.template))
  },
  mounted() {
    getTemplate().then((res) => {
      let cartBlock = {
        ...res,
        template_id: JSON.parse(localStorage.getItem('templateId')),
      }
      const invoice = postInvoiceNumber(cartBlock)
      invoice
        .then((res) => {
          this.$router.push(
            `/checkout/fix/${res.data.internal_id}?template=${JSON.parse(
              localStorage.getItem('templateId'),
            )}`,
          )
        })
        .catch(() => {
          console.log('Заказ не найден')
        })
    })
  },
}
</script>
