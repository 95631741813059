<template>
  <section class="choose-amount">
    <div class="choose-amount__other active none-buttons">
      <h2 class="order__title choose-amount__title">Введите сумму</h2>
      <div class="choose-amount__input" @click="$refs.otherAmountInput.focus()">
        <input
          name="other"
          v-model="otherAmount"
          ref="otherAmountInput"
          type="number"
          placeholder="0"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { CHANGE_TOTAL, CHANGE_TOTAL_ERROR } from '@/store/mutations.type'

export default {
  data: () => ({
    otherAmount: null,
  }),
  computed: {
    ...mapGetters(['amountButtons']),
  },
  methods: {
    ...mapMutations({
      changeAmount: CHANGE_TOTAL,
      changeTotalError: CHANGE_TOTAL_ERROR,
    }),
    chooseButton(id) {
      this.changeTotalError(false)
      this.buttons = this.buttons.map((button) =>
        button.id === id ? { ...button, active: true } : { ...button, active: false },
      )
      if (id) {
        this.$refs.otherAmountButton.classList.remove('active')
        this.showOtherAmount = false
        this.changeAmount(this.buttons.find((button) => button.id === id).price)
      } else {
        this.$refs.otherAmountButton.classList.add('active')
        this.otherAmount = null
        this.changeAmount(this.otherAmount)
        this.showOtherAmount = true
      }
    },
  },
  watch: {
    amountButtons(val) {
      this.buttons = val
    },
    otherAmount(val) {
      this.changeTotalError(false)
      this.totalPriceError = false
      this.changeAmount(val)

      this.$refs.otherAmountInput.style.width = val.length
        ? `${val.length * 9}px`
        : '16px'
    },
  },
}
</script>

<style lang="scss">
.choose-amount {
  &__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 19px;
  }

  &__button {
    height: 60px;
    border: 1px solid #212125;
    border-radius: 9px;
    background-color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #212125;
    padding: 8px 24px;
    transition: all 0.5s;

    &.active {
      background-color: #212125;
      border-radius: 10px;
      color: #fff;
    }
  }

  &__other {
    overflow: hidden;
    height: 0;
    transition: all 0.5s;

    &.active {
      height: 146px;
      padding-top: 32px;
    }

    &.none-buttons {
      height: 120px;
      padding-top: 0;
    }
  }

  &__input {
    border: 2px solid #f7f7f7;
    width: 100%;
    border-radius: 10px;
    padding: 0 14px;

    &::after {
      content: '₽';
      position: relative;
      left: 10px;
    }

    input {
      width: 9px;
      padding: 24px 0;
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      max-width: 90%;

      &::placeholder {
        font-family: 'Open Sans', sans-serif;
        color: #909192;
        font-size: 18px;
        letter-spacing: -0.67px;
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}
</style>
