<template>
  <!-- eslint-disable -->
  <div v-if="qty || type === 'tickets'">
    <div v-for="(prod, index) in product.prices" :key="prod.id">
      <div class="editable-products__product-name">
        {{ product.name + ' ' + prod.description }}
      </div>
      <ItemsButton
        :price="product.prices[index].price"
        :index="index"
        :quantity="qty"
        :onAdd="add"
        :onRemove="remove"
        @setIndex="setIndex"
      />
    </div>
  </div>
  <!-- eslint-enable -->
</template>

<script>
import ItemsButton from '@/components/ItemsButton'
import editableItemsItem from '@/mixins/editableItemsItem'
import cartBlockChange from '@/mixins/cartBlockChange'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    productSizeIndex: 0,
  }),
  mixins: [editableItemsItem, cartBlockChange],
  props: ['product', 'type'],
  components: {
    ItemsButton,
  },
  computed: {
    ...mapGetters(['localProducts', 'total']),
    cartProduct() {
      return this.cartBlock.body
        .find((item) => item.name === 'items')
        ?.value.find((product) => product.name === this.product.name)
    },
  },
  watch: {
    'product.count'(val) {
      this.cartProduct.quantity = val
    },
    total(val) {
      this.updateCartBlock(val)
    },
  },
  methods: {
    setIndex(index) {
      this.productSizeIndex = index
    },
    updateCartBlock(total) {
      this.cartBlock.body.find((item) => item.name === 'items').value =
        this.localProducts
      this.cartBlock.body.find((item) => item.name === 'total').value = total
    },
  },
  mounted() {
    if (this.cartBlock) {
      this.updateCartBlock(this.total)
    }
  },
}
</script>
