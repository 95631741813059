<template>
  <section>
    <!-- Данный компонент создан под отображение заказанных товаров со страницы имеющей модификаторы -->
    <EditableProducts />
    <ReceiptSend />
    <PayMethods />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

import EditableProducts from './AvailableItemsBlock/EditableProducts'
import ReceiptSend from './ReceiptSend'
import PayMethods from './PayMethods'

export default {
  components: {
    EditableProducts,
    ReceiptSend,
    PayMethods,
  },
  computed: {
    ...mapGetters({
      paymentSuccess: 'pay/paymentSuccess',
      payResultData: 'pay/payResultData',
    }),
  },
  watch: {
    paymentSuccess: function(status) {
      if (status) {
        this.$router.push({ name: 'Checkout Success', query: { invoice_id: this.payResultData.internal_id } })
      }
    },
  },
}
</script>
