<template>
  <section class="choose-amount">
    <template v-if="amountButtons.length">
      <h2 class="order__title choose-amount__title">Выберите цену</h2>
      <div class="choose-amount__buttons">
        <button
          v-for="button in amountButtons"
          :key="button.id"
          class="choose-amount__button"
          :class="{ active: button.active }"
          @click="chooseButton(button)"
        >
          <Money
            v-if="button.item_tags[0] === 'fix'"
            :value="button.name"
            :frac-digits="false"
          />
          <span v-else>{{ button.name }}</span>
        </button>
      </div>
    </template>

    <div class="choose-amount__other" :class="{ active: showOtherAmount }">
      <h2 class="order__title choose-amount__title">Введите сумму</h2>

      <div @click="$refs.otherAmount.focus()" class="choose-amount__input-wrap">
        <label>
          <input
            ref="otherAmount"
            v-model="otherAmount"
            @input="changeOtherAmount()"
            type="number"
            class="choose-amount__input"
            placeholder="0"
            :style="otherAmountStyle"
          />
        </label>
      </div>
    </div>

    <h3 class="error-message" :class="{ show: totalError }">Вы ничего не выбрали</h3>
  </section>
</template>

<script>
import Money from '@/components/Money'
import { mapGetters, mapMutations } from 'vuex'
import {
  CHANGE_TOTAL,
  CHANGE_TOTAL_ERROR,
  TIPS_BUTTONS_CHANGE,
} from '@/store/mutations.type'
import cartBlockChange from '@/mixins/cartBlockChange'

export default {
  mixins: [cartBlockChange],
  components: {
    Money,
  },
  data: () => ({
    buttons: [],
    showOtherAmount: false,
    otherAmount: null,
    otherAmountStyle: { width: '15px' },
  }),
  computed: {
    ...mapGetters(['amountButtons', 'totalAmount', 'totalError']),
  },
  methods: {
    ...mapMutations({
      changeAmount: CHANGE_TOTAL,
      changeTotalError: CHANGE_TOTAL_ERROR,
      changeButtons: TIPS_BUTTONS_CHANGE,
    }),
    chooseButton({ id, item_tags }) {
      const tags = item_tags || new Array()
      this.changeTotalError(false)
      const buttons = this.amountButtons.map((button) =>
        button.id === id ? { ...button, active: true } : { ...button, active: false },
      )
      this.changeButtons(buttons)
      if (tags.includes('fix')) {
        this.showOtherAmount = false
        this.changeAmount(buttons.find((button) => button.id === id).price)
      } else {
        this.otherAmount = null
        this.changeAmount(this.otherAmount)
        this.showOtherAmount = true
      }
    },
    changeCartBlock(items, total) {
      if (this.cartBlock) {
        let choosenItem = items.find((item) => String(item.price) === String(total))
        let otherItem = items.find((item) => (item.tags || new Array()).includes("custom")) || {}
        this.cartBlock.body.find((item) => item.name === 'items').value = [
          choosenItem ? { ...choosenItem, quantity: 1 } : { ...otherItem, quantity: 1, price: total }
        ]
        this.cartBlock.body.find((item) => item.name === 'total').value = total
      }
    },
    changeOtherAmount() {
      this.otherAmountStyle.width = `${(this.otherAmount.length || 1) * 9.2 + 5}px`
    },
  },
  watch: {
    otherAmount(val) {
      this.changeTotalError(false)
      this.totalPriceError = false
      this.changeAmount(val)
    },
    totalAmount(val) {
      this.changeCartBlock(this.amountButtons, val)
    },
  },
  mounted() {
    this.changeCartBlock(this.amountButtons, this.totalAmount)
    this.changeAmount(0)
  },
}
</script>

<style lang="scss">
@import '~@/assets/scss/components/choose-amount.scss';
</style>
