import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      menuQty: (state) => state.menu.qty,
    }),
    ...mapGetters(['total', 'menuBlockMain', 'itemsBlockMain']),
    products() {
      if (this.menuBlockMain) {
        const allMenu = this.menuBlockMain?.body?.find((b) => b.name === 'items').value || []
        return allMenu
          .filter((product) => this.menuQty[product.name])
          .map((product) => ({
            ...product,
            count: this.menuQty[product.name],
          }))
      }
      if (this.itemsBlockMain) {
        let dd = this.itemsBlockMain?.body?.find((b) => b.name === "items").value;
        dd.forEach((element, i) => {
          let d = element.prices.filter((el, index) => {
            return this.menuQty[element.name][index] > 0;
          });
          dd[i].prices = d;
        });
        let data = [];
        dd.forEach((element) => {
          if (element.prices.length > 0) {
            data.push({ ...element, count: this.menuQty[element.name] });
          }
        });
        return data;
      }
    },
  },
}
