<template>
  <div class="fail">
    <Header :withMerchant="false" />
    <section class="main">
      <div class="fail__message">Ваша оплата не прошла :(</div>
      <div class="fail__reason">Не хватает средств</div>
      <div>
        <button @click="goToPay()">← Попробовать ещё раз</button>
      </div>
    </section>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  components: {
    Header,
  },
  methods: {
    goToPay() {
      this.$router.replace('/pay')
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables';

.fail {
  &__message,
  &__reason {
    font-size: 43px;
    font-weight: 300;
  }

  &__reason {
    margin-top: 43px;
  }

  button {
    height: 78px;
    margin-top: 56px;
    width: 100%;
    font-size: 21px;
    border-radius: 18px;
    border: none;
    color: #fff;
    background-color: $color;
  }
}
</style>
