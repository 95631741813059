<template>
  <div v-if="qty || type === 'tickets'">
    <div class="editable-products__product-name">{{ product.name }}</div>
    <MenuButton :price="product.price" :quantity="qty" :onAdd="add" :onRemove="remove" />
  </div>
</template>

<script>
import MenuButton from '@/components/MenuButton'
import editableMenuItem from '@/mixins/editableMenuItem'
import cartBlockChange from '@/mixins/cartBlockChange'
import { mapGetters } from 'vuex'

export default {
  mixins: [editableMenuItem, cartBlockChange],
  props: ['product', 'type'],
  components: {
    MenuButton,
  },
  computed: {
    ...mapGetters(['localProducts', 'total']),
    cartProduct() {
      return this.cartBlock.body
        .find((item) => item.name === 'items')
        ?.value.find((product) => product.name === this.product.name)
    },
  },
  watch: {
    'product.count'(val) {
      this.cartProduct.quantity = val
    },
    total(val) {
      this.updateCartBlock(val)
    },
  },
  methods: {
    updateCartBlock(total) {
      this.cartBlock.body.find((item) => item.name === 'items').value =
        this.localProducts
      this.cartBlock.body.find((item) => item.name === 'total').value = total
    },
  },
  mounted() {
    if (this.cartBlock) {
      this.updateCartBlock(this.total)
    }
  },
}
</script>
